/* Wings - Written by Timothy Phillips
 * Wings extend to the sides beyond the content area. The origional Rebrand template and matrix output isn't well suited to this, so there's some heavy JS involved.
 * Required by:
 * - Hero blocks
 * - Info banner */

@import "../_variables";



div.winged-block {
	@include childNoMargins;
	position: relative;
	margin: $size4 0;
	//padding: $size4 0
	//background-color: $colBaseGrey
	background-position: center 20%;
	background-size: cover;
	background-repeat: no-repeat;
	transition: background-color $animSpeed ease;
	overflow: hidden;
	&.background-tiled {
		background-repeat: repeat;
		background-size: auto; }

	// &:not(.hero-block)
	// 	// padding: $size4 0
	// 	> div.wings-background + *
	// 		margin-top: 0px
	// 	> *:last-child
	// 		margin-bottom: 0px
	&.margins-normal {
		margin: $size2 0;
		padding: $size2 0; }
	&.margins-large {
		margin: $size4 0;
		padding: $size4 0; }
	> * {
		z-index: $zIndex + 20;
		position: relative;
		max-width: $pageContentMaxWidthPlus + $pageGutter * 2;
		margin-left: auto !important;
		margin-right: auto !important; }

	&.expand-content > * {
		max-width: 1920px; }
	&.background-white {
		background-color: white; }
	&.background-grey {
		background-color: $colBaseGrey; }
	&.background-light {
		background-color: $colBaseLighter; }
	&.background-blue, &.background-dark {
		background-color: $colBlue; }

	&.background-white,
	&.background-grey,
	&.background-light,
	&.background-blue,
	&.background-dark {
		div.info-banner, div.carousel-wrapper {
			background-color: transparent; } }

	&.padding-none {
		> div.wings-content {
			padding: 0px; } }

	+ div.winged-block {
		margin-top: -$size4; } }

div.content > div.winged-block:first-child {
	margin-top: -$size4 !important; }

div.wings-background {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: $zIndex + 20;
	background-position: center 20%;
	background-size: cover;
	background-repeat: no-repeat;
	&.background-tiled {
		background-repeat: repeat;
		background-size: auto; }
	&.no-image {
		background-color: $colBaseGrey; } }


div.wings-content {
	//background-color: $colBaseGrey

	@include childNoMargins;
	position: relative;
	padding: $size4 $size2;
	max-width: $pageContentMaxWidthPlus + $pageGutterSmall * 2;
	margin-left: 0px;
	margin-right: 0px;
	@media (max-width: $pageContentMaxWidth + $pageGutterSmall * 2) {
		width: 100%;
		padding-left: calc(12px + 12 * (100vw - 480px) / 480);
		padding-right: calc(12px + 12 * (100vw - 480px) / 480); }
	@media (max-width: 480px) {
		padding-left: $pageGutterTiny;
		padding-right: $pageGutterTiny; }

	> * {
		margin-left: auto;
		margin-right: auto;
		// //max-width: 1920px
		// margin: 0px auto
		// +mediaMinHuge
 }		// 	margin: 0px auto

	&.info-banner, &.hero-block {
		padding: 0px; }


	ul[class^="grid-"], ul[class*=" grid-"] {
		&:first-child:last-child {
			margin-top: -$size2 !important;
			margin-bottom: -$size2;
			margin-left: -$size1;
			margin-right: -$size1;
			padding-top: 0px;
			padding-bottom: 0px; } }

	&.padding-vertical-none {
		ul[class^="grid-"], ul[class*=" grid-"] {
			&:first-child:last-child {
				padding-top: $size2;
				padding-bottom: $size2; } } } }

div.winged-block.expand-content > div.wings-content {
	max-width: $pageContentMaxWidthSuper; } //1920px

/* how it affects other devices */
div.winged-block:not(.hero-block) {
	ul.lightbox-thumbnails > li > a {
		background: $colBaseLightGrey;
		border-color: $colBaseLightGrey; } }
